import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ISlide, ISlideObject, Slide, SlidePlaylist } from '../shared/slide';
import { Eventstatus } from '../shared/eventstatus';
import { EventconfigStoreService } from '../shared/eventconfig-store.service';
import { SlideStoreService } from '../shared/slide-store.service';
import { LogService } from '../shared/log.service';
import { VideoplayerComponent } from '../videoplayer/videoplayer.component';
import { PlayerStoreService } from '../shared/player-store.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
@Component({
  selector: 'lse-slidelist',
  templateUrl: './slidelist.component.html',
  styleUrl: './slidelist.component.css',
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('1.5s ease-in', style({ height: 0, opacity: 1 }))
      ]),
      transition(':leave', [
        style({ height: 0, opacity: 1 }),
        animate('1s ease-in', style({ height: 0, opacity: 0 }))
      ])
    ]),
    trigger('slideInOut', [
      state(
        'in',
        style({
          overflow: 'hidden',
          height: '*'
        })
      ),
      state(
        'out',
        style({
          overflow: 'hidden',
          height: '0px'
        })
      ),
      transition('in => out', animate('250ms ease-in-out')),
      transition('out => in', animate('250ms ease-in-out'))
    ])
  ]
})

export class SlidelistComponent implements OnInit {
  @ViewChild('slideHistoryChild', { read: ElementRef })
  public slideHistoryChild: ElementRef<any>;
  @ViewChild('slideHistoryContent', { read: ElementRef })
  public slideHistoryContent: ElementRef<any>;
  @ViewChild('slideDiv') slideDiv: ElementRef;
  @Input() selectedSlide: SlidePlaylist;
  @Input() phase: string;
  @Input() eStatus: Eventstatus;
  @Input() eLangGlob: any;
  @Input() slideIsCustom: any;
  @Input() eventFolder: string;
  @Input() languages: string[];
  @Input() selectedLanguage: string;
  @Output() playAtEvent = new EventEmitter();
  @Output() showElement = new EventEmitter();
  @Output() selectSlide = new EventEmitter();

  // playlist: Slide[];
  holdMyTime: any;
  path: string;
  slideIndex: number;
  hasScrolled = false;
  scrollMax = 0;
  showSlideFlow = 'in';
  screenwidth = document.body.offsetWidth;

  private readonly subscriptions : Subscription[] = [];

slideStoreService: SlideStoreService = inject(SlideStoreService);

  public slidePlaylist: SlidePlaylist[] = [];


  constructor(
    private es: EventconfigStoreService,
    private ss: SlideStoreService,
    private logger: LogService,
    private playerComponent: VideoplayerComponent,
    private playerStoreService: PlayerStoreService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.slideStoreService.playlist$.subscribe((playlist: SlidePlaylist[]) => {
        this.slidePlaylist = [...playlist];
      })
    );
    setTimeout(() => {
      this.scrollToMid();
    }, 100);
    // if (this.es.eState) this.playlist.reverse();
    this.checkNewSlide();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
  }

  onshowElement(e: string) {
    this.showElement.emit(e);
  }

  setSelectSlide(slide: SlidePlaylist) {
    switch (this.phase) {
      case 'live' || 'pre-live':
        this.selectSlide.emit(slide)
        break;
      case 'post-live':
        this.selectSlide.emit(slide);
        break;
    }
  }

  scrollToMid() {
    if (this.phase === 'post-live') {
      setInterval(() => {
        let oldIndex = this.slideIndex;
        this.slideIndex = this.slidePlaylist.findIndex(
          (x) => x._id === this.selectedSlide._id
        );
        if (oldIndex !== this.slideIndex) {
          if (!this.hasScrolled) {
            if (this.slideIndex > 1) {
              const e =
                this.slideIndex *
                (this.slideHistoryContent.nativeElement.scrollWidth /
                  this.slidePlaylist.length) -
                (this.slideHistoryContent.nativeElement.offsetWidth / 2 - 70);
              //this.logger.log('ScrollTo: ' + this.slideDiv.nativeElement.offsetWidth);
              this.slideHistoryContent.nativeElement.scrollTo({
                left: e,
                behavior: 'smooth'
              });
            }
          } else {
            this.hasScrolled = false;
          }
        }
      }, 500);
    } else {
      this.scrollMax =
        this.slideHistoryContent.nativeElement.scrollWidth -
        this.slideHistoryContent.nativeElement.clientWidth;
      this.logger.log(
        'Scroll Left: ' + this.slideHistoryContent.nativeElement.scrollWidth
      );
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.scrollMax,
        behavior: 'smooth'
      });

    }
  }


  checkNewSlide() {
    setInterval(() => {
      //this.logger.log('SlideScroll: ' + this.hasScrolled);
      // this.getLazy();
      if (
        !this.hasScrolled &&
        this.scrollMax <
        this.slideHistoryContent.nativeElement.scrollWidth -
        this.slideHistoryContent.nativeElement.clientWidth
      ) {
        console.log('checkNewSlide');
        this.scrollToMid();
        this.scrollMax =
          this.slideHistoryContent.nativeElement.scrollWidth -
          this.slideHistoryContent.nativeElement.clientWidth;
      }
    }, 2000);
  }

  // retrieveSlideFlow() {
  // }

  handleEvent(value) {
    this.holdMyTime = value;
    this.playAtEvent.emit(value);
    this.logger.log(value);
  }



  scrollToCenter(value) {
    setTimeout(() => {
      const i = this.slidePlaylist.findIndex((x) => x._id === this.selectedSlide._id);
      this.logger.log('Scroll to Center ' + i);
      if (i > 1) {
        const e =
          i *
          (this.slideHistoryContent.nativeElement.scrollWidth /
            this.slidePlaylist.length) -
          (this.slideHistoryContent.nativeElement.offsetWidth / 2 - 70);
        // this.logger.log('ScrollTo: ' + this.slideDiv.nativeElement.offsetWidth);
        this.slideHistoryContent.nativeElement.scrollTo({
          left: e,
          behavior: 'smooth'
        });
      }
    }, 200);
  }

  playAtFunction(timestamp) {
    console.log('triggered playatfunc', timestamp);
    this.playAtEvent.emit(timestamp);

  }

  scroll(direction: string) {
    console.log('scroll() direction:', direction);
    this.logger.log('Direction: ' + direction);
    if (direction === 'left') {
      this.hasScrolled = true;
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.slideHistoryContent.nativeElement.scrollLeft - 150,
        behavior: 'smooth'
      });
    } else if (direction === 'right') {
      this.slideHistoryContent.nativeElement.scrollTo({
        left: this.slideHistoryContent.nativeElement.scrollLeft + 150,
        behavior: 'smooth'
      });
      if (
        this.slideHistoryContent.nativeElement.scrollLeft ===
        this.slideHistoryContent.nativeElement.scrollWidth -
        this.slideHistoryContent.nativeElement.clientWidth
      ) {
        this.hasScrolled = false;
      }
      if (this.phase === 'post-live') {
        this.hasScrolled = true;
      }
    }
  }

  toggleSlideflow() {
    this.showSlideFlow = this.showSlideFlow === 'out' ? 'in' : 'out';
  }
}

