import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  LangGlobalsCN,
  LangGlobalsDE,
  LangGlobalsEN,
  LangGlobalsES,
  LangGlobalsJP,
  LangGlobalsNL,
  LangGlobalsPT
} from '../_globals/language_globals';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { Eventconfig } from './eventconfig';
import { EventconfigRaw } from './eventconfig-raw';
import { Eventstatus } from './eventstatus';
import { Injectable } from '@angular/core';
import { LogService } from './log.service';
import { Secondlogo } from './eventsetup';
import { ISlide, ISlideObject, Slide, SlidePlaylist } from './slide';
import { StrawpollConfig } from './strawpoll-config';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
/**
 * Zentraler Service (FR - 2021/02/10)
 * Hauptservice zur Steuerung der Applikation. Ruft Eventconfig und -status aus der Api ab und gibt diese zurück.
 * Legt Informationen wie die aktuell angezeigte Slide ab und speichert Applikationszustände.
 */
export class EventconfigStoreService {
  private api = environment.apiUrl + 'api'; // @TODO: #4 API und weitere Variablen in globale Config-Datei auslagern.
  private socket;
  token: string; // Eventtoken
  eInit: string; // Dateipfad zum Event auf dem Server
  activeLang = 'de'; // Zurzeit aktive Sprache.
  slideIsCustom = false; // Gibt an, ob die aktuell angezeigte Slide und die Live-Slide abweichen. Default false.
  liveSlide: Slide; // Live-Slide vom Typ Slide.
  activeSlide: Slide;// Aktuell angezeigte Slide vom Typ Slide.
  eState: string;
  uInternal = false; // Nutzer ist initial nicht intern.
  slidePlaylist: Slide[];
  playlist: ISlide[];
  storedConfig: Eventconfig;
  storedStatus: Eventstatus;
  secondLogoWidth: number;

  liveSlideNEW: SlidePlaylist; // Live-Slide vom Typ Slide.
  activeSlideNEW: SlidePlaylist;// Aktuell angezeigte Slide vom Typ Slide.

  constructor(private http: HttpClient, private logger: LogService) {
  }

  setEventWatcherSocket(socket) {
    this.socket = socket;
  }

  getEventWatcherSocket() {
    return this.socket;
  }

  // Getter-Methoden

  initEvent(token: string) {
    this.logger.log('init');
    return this.http
      .get<EventInit>(`${this.api}/init.php?x=getDataPath&token=${token}`, { responseType: 'json' })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getEventLang() {
    return this.http.get<any>(`${this.api}/api.php?x=get_event_language`, { responseType: 'json' }).pipe(retry(1), catchError(this.errorHandler));
  }

  getConfig(lang: string) {
    return this.http
      .get<EventconfigRaw>(`${this.api}/api.php?x=get_event_config&lang=${lang}`, { responseType: 'json' })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getStrawpollVotingConfig(lang: string) {
    return this.http
      .get<StrawpollConfig>(`${this.api}/api.php?x=get_strawpoll_voting_config&lang=${lang}`, { responseType: 'json' })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getStrawpollResult() {
    return this.http
      .get<StrawpollConfig>(`${this.api}/api.php?x=get_strawpoll_result`, { responseType: 'json' })
      .pipe(retry(1), catchError(this.errorHandler));
  }

  getStatus() {
    return this.http
      .get<Eventstatus>(`${this.api}/api.php?x=get_event_status`)
      .pipe(retry(3), catchError(this.errorHandler));
  }

  getLiveSlide(slideSwitch: boolean) {
    if (slideSwitch) {
      return this.http
        .get<Slide>(`${environment.apiUrl}/mongodb/slidemanagement/getSlidePlaylist`)
        .pipe(retry(3), catchError(this.errorHandler));
    } else {
      return this.http
        .get<Slide>(`${this.api}/api.php?x=get_slides_playlist`)
        .pipe(retry(3), catchError(this.errorHandler));
    }
  }

  getActiveSlide()  {
    if (this.slideIsCustom) {
      return this.activeSlide;
    } else {
      this.activeSlide = this.liveSlide;
      return this.activeSlide;
    }
  }

  getActiveSlideNEW() {
    console.log(this.activeSlideNEW)
    return this.activeSlideNEW;
  }

  getIsCustomSlide() {
    return this.slideIsCustom;
  }

  getSlidePlaylist(slideSwitch: boolean) {
    if (slideSwitch) {
      return this.http
        .get<ISlide[]>(`${environment.apiUrl}/v1/ccp-backend/slidemanagement/getSlidePlaylist`)
        .pipe(retry(3), catchError(this.errorHandler));
    } else {
      return this.http
        .get<Slide[]>(`${this.api}/api.php?x=get_slides_playlist`)
        .pipe(retry(3), catchError(this.errorHandler));
    }
  }

  setConfigStore(config: Eventconfig) {
    this.storedConfig = config;
  }

  getStoredConfig() {
    return this.storedConfig;
  }

  setStatusStore(status: Eventstatus) {
    this.storedStatus = status;
  }

  getStoredStatus() {
    return this.storedStatus;
  }

  setSlidePlaylist(slides: any) {
    this.slidePlaylist = slides;
  }

  setPlaylist(slides: any) {
    this.playlist = slides;
  }

  getMyPlaylist() {
    return this.slidePlaylist;
  }

  getToken() {
    return this.token;
  }

  getInitCipher() {
    return this.eInit;
  }

  getActiveLang() {
    return this.activeLang;
  }

  getLangGlobals() {
    if (this.activeLang === 'de') {
      return LangGlobalsDE;
    } else if (this.activeLang === 'en') {
      return LangGlobalsEN;
    } else if (this.activeLang === 'nl') {
      return LangGlobalsNL;
    } else if (this.activeLang === 'pt') {
      return LangGlobalsPT;
    } else if (this.activeLang === 'es') {
      return LangGlobalsES;
    } else if (this.activeLang === 'cn') {
      return LangGlobalsCN;
    } else if (this.activeLang === 'jp') {
      return LangGlobalsJP;
    } else {
      return LangGlobalsEN;
    }
  }

  getEventState() {
    return this.eState;
  }

  getSecondLogo(id: number, useDefault: boolean) {
    return this.http
      .get<Secondlogo>(`${this.api}/api.php?x=get_second_logo&id=${id}&useDefault=${useDefault}`)
      .pipe(retry(3), catchError(this.errorHandler));
  }

  getSecondLogoWidth(): string {
    return `
      @media only screen and (min-width: 1280px) {
        #secondLogo {
          width: ${this.secondLogoWidth}px;
        }
      }
      @media only screen and (min-width: 1024px) and (max-width: 1279px) {
        #secondLogo {
          width: calc(0.8 * ${this.secondLogoWidth}px);
        }
      }
      @media only screen and (min-width: 512px) and (max-width: 1023px) {
        #secondLogo {
          width: ${this.secondLogoWidth}px;
        }
      }
      @media only screen and (max-width: 512px) {
        #secondLogo {
          width: calc(0.8 * ${this.secondLogoWidth}px);
        }
      }`;
  }

  setVoteHeight(value: string): string {
    if (value === undefined || value === '0') {
      return '';
    } else {
      return `
      #votingData{
        overflow: auto;
        max-height: ${value}px;
      }
      `;
    }
  }

  // Setter-Methoden

  setLiveSlide(slide: Slide) {
    this.liveSlide = slide;
  }

  setSecondLogoWidth(width: number) {
    this.secondLogoWidth = width;
  }

  setActiveSlide(slide: Slide) {
    // console.log('setActiveSlide', slide.slide, slide.tc);
    this.slideIsCustom = true;
    this.activeSlide = slide;
    //this.logger.log('Active Slide ' + this.activeSlide.slide);
  }

  setLiveSlideNEW(slide: SlidePlaylist) {
    this.liveSlideNEW = slide;
  }

  getLiveSlideNEW() {
    return this.liveSlideNEW;
  }

  setActiveSlideNEW(slide: SlidePlaylist) {
    // console.log('setActiveSlide', slide.slide, slide.tc);
    this.slideIsCustom = true;
    this.activeSlideNEW = slide;
    //this.logger.log('Active Slide ' + this.activeSlide.slide);
  }


  setSlideToLive() {
    this.slideIsCustom = false;
  }

  setToken(token: string) {
    this.token = token;
  }

  setInitCipher(cipher: string) {
    this.eInit = cipher;
  }

  setActiveLang(lang: string) {
    this.activeLang = lang;
  }

  sendStrawpollVote(vote): Observable<any> {
    this.logger.log('Message ' + vote);
    return this.http.post(this.api + '/api.php?x=strawpoll_user_vote', {
      strawpoll_voting: vote
    });
  }

  makeRandom() {
    const possible = 'abcdefghijklmnopqrstuvwxyz1234567890';
    const lengthOfCode = 10;
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  setEventState(state: string) {
    this.eState = state;
  }

  getSlidePlaylistNEW() {
    return this.http
      .get<ISlide[]>(`${environment.apiUrl}/v1/ccp-backend/slidemanagement/getSlidePlaylistNEW`)
      .pipe(retry(3), catchError(this.errorHandler));
  }

  // Private Methoden

  private errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Fehler aufgetreten!');
    if (error.status === 401) {
      window.sessionStorage.clear();
      window.location.reload();
      console.error('Unautherized!');
    }
    return throwError(error);
  }
}
