import { AfterViewInit, Component, inject, Input, OnInit, ViewChild } from '@angular/core';

import { LogService } from '../shared/log.service';
import { ISlide, ISlideObject, Slide } from '../shared/slide';
import { SlideStoreService } from '../shared/slide-store.service';
import { environment } from '../../environments/environment';
import { constants } from '../_constant/constants';

@Component({
  selector: 'lse-slide-image',
  template: `

    <div class='slide_container'>
        <img #slideId class='ui image'
             src='{{environment.apiUrl}}/event/{{this.eventFolder}}/{{constant.SLIDES_FOLDER_PATH}}/{{this.selectedSlide.slide[languages[0]]}}_1200.webp'/>
    <div class='slide_bar'>
      <div class='fullscreen_button'>
        <i (click)='openFullscreen()' class='fullscreenicon'></i>
      </div>
    </div>
  </div>`,
  styles: `.slide_bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    font-size: 18px;
    color: white;
    padding-right: 15px;
    position: absolute;
    left: 0;
    width: 100%;
    transition: 1s ease;
    opacity: 0;
    background-color: rgba(43, 51, 63, 0.7);
  }

  .fullscreen_button{
    cursor: pointer;
  }

  .slide_container{
    display: flex;
    align-items: flex-end;
  }

  .slide_container:hover .slide_bar {
    opacity: 1;
  }

  @media only screen and (max-width: 1023px) {
    .slide_bar{
      display: none;
    }
  }`,
})
export class SlideImageComponent implements OnInit{
  @Input() selectedSlide: ISlideObject;
  @Input() languages: string[];
  @Input() eventFolder: string;

  @ViewChild('slideId') slideFullscreen;

  protected readonly constant = constants;
  protected readonly environment = environment;
  slideStoreService: SlideStoreService = inject(SlideStoreService);
  logger: LogService = inject(LogService);

  ngOnInit(): void {

    this.logger.log('Init Slide: ');
  }

  openFullscreen() {
    const elem = this.slideFullscreen.nativeElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

}
