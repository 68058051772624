import {
  AfterViewInit,
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { LogService } from '../shared/log.service';
import { ISlide, ISlideObject, Slide, SlidePlaylist } from '../shared/slide';
import { SlideStoreService } from '../shared/slide-store.service';
import { environment } from '../../environments/environment';
import { constants } from '../_constant/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lse-slide-image',
  template: `
    <div class='slide_container'>
      @defer (on viewport) {
        <img #slideId class='ui image'
               src='{{environment.apiUrl}}/event/{{this.eventFolder}}/{{constant.SLIDES_FOLDER_PATH}}/{{this.selectedSlide.slide[languages[0]]}}_1200.webp'/>
        <div class='slide_bar'>
          <div class='fullscreen_button'>
            <i (click)='openFullscreen()' class='fullscreenicon'></i>
          </div>
        </div>
      } @placeholder (minimum 1ms){
          <img class='ui image' src='assets/images/placeholder/placeholder_400.webp'/>
      } @error {
        Could not load image
      }
    </div>`,
  styles: `.slide_bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    font-size: 18px;
    color: white;
    padding-right: 15px;
    position: absolute;
    left: 0;
    width: 100%;
    transition: 1s ease;
    opacity: 0;
    background-color: rgba(43, 51, 63, 0.7);
  }

  .fullscreen_button{
    cursor: pointer;
  }

  .slide_container{
    display: flex;
    align-items: flex-end;
  }

  .slide_container:hover .slide_bar {
    opacity: 1;
  }

  @media only screen and (max-width: 1023px) {
    .slide_bar{
      display: none;
    }
  }`,
})
export class SlideImageComponent implements OnInit, OnChanges, OnDestroy{
  @Input() selectedSlide: SlidePlaylist;
  @Input() languages: string[];
  @Input() eventFolder: string;

  @ViewChild('slideId') slideFullscreen;

  protected readonly constant = constants;
  protected readonly environment = environment;
  private subscriptions: Subscription[] = [];
  slideStoreService: SlideStoreService = inject(SlideStoreService);
  logger: LogService = inject(LogService);

  ngOnInit(): void {
    this.slideStoreService.getSlidePlaylist();
    this.subscriptions.push(
      this.slideStoreService.playlist$.subscribe((playlist: SlidePlaylist[]) => {
        this.selectedSlide = [...playlist][0];
      })
    );
    this.slideStoreService.initializeSocketConnection()
    this.logger.log('Init Slide: ');
  }

  openFullscreen() {
    const elem = this.slideFullscreen.nativeElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }

  ngOnChanges(changes:SimpleChanges): void {

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.slideStoreService.socket.disconnect();
  }

}
