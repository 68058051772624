import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VideoplayerComponent } from './videoplayer/videoplayer.component';
import { SlideComponent } from './slide/slide.component';
import { SlideflowListComponent } from './slideflow-list/slideflow-list.component';
import { SlideflowListItemComponent } from './slideflow-list-item/slideflow-list-item.component';
import { UserAskQuestionComponent } from './user-ask-question/user-ask-question.component';
import { UserSeeQuestionComponent } from './user-see-question/user-see-question.component';
import { UserStrawpollComponent } from './user-strawpoll/user-strawpoll.component';
import { VodQuestionListComponent } from './vod-question-list/vod-question-list.component';
import { VodQuestionListItemComponent } from './vod-question-list-item/vod-question-list-item.component';
import { FormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { TokenInterceptor } from './interceptors/token-interceptor';
import { LoginComponent } from './login/login.component';
import { LogService } from './shared/log.service';
import { NgChartsModule } from 'ng2-charts';
import { UserVoteQuestionListComponent } from './user-vote-question-list/user-vote-question-list.component';
import {
  UserVoteQuestionListItemComponent
} from './user-vote-question-list-item/user-vote-question-list-item.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgOptimizedImage } from '@angular/common';
import { DescriptionComponent } from './description/description.component';
import { SlidelistComponent } from './slidelist/slidelist.component';
import { SlidelistItemComponent } from './slidelist/slidelist-item/slidelist-item.component';
import { SlideImageComponent } from './slide-image/slide-image.component';


@NgModule({ declarations: [
        AppComponent,
        VideoplayerComponent,
        SlideComponent,
        SlideflowListComponent,
        SlideflowListItemComponent,
        UserAskQuestionComponent,
        UserSeeQuestionComponent,
        UserStrawpollComponent,
        VodQuestionListComponent,
        VodQuestionListItemComponent,
        HomeComponent,
        LoginComponent,
        UserVoteQuestionListComponent,
        UserVoteQuestionListItemComponent,
        DescriptionComponent,
        SlidelistComponent,
        SlidelistItemComponent,
        SlideImageComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgxPaginationModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        NgChartsModule,
        ClickOutsideModule,
        NgOptimizedImage], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        Title,
        LogService,
        VideoplayerComponent,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
