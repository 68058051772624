import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ISlide, ISlideObject, Slide, SlidePlaylist } from '../../shared/slide';
import { constants } from '../../_constant/constants';
import { environment } from '../../../environments/environment';
import { EventconfigStoreService } from '../../shared/eventconfig-store.service';
import { SlideStoreService } from '../../shared/slide-store.service';
import { timestamp } from 'rxjs';

@Component({
  selector: 'lse-slidelist-item',
  standalone: false,
  template: `
    <div class='mini_div'>
      @defer (on viewport){
      <img
        (click)='setActiveSlide()'
        (click)='playbackAtTimestamp(slidePlaylistObject.timestamp)'
        class='mini_slide'
        [ngClass]="{ 'mini-highlight': this.slidePlaylistObject._id  }"
        src='{{environment.apiUrl}}/event/{{this.eventFolder}}/{{constant.SLIDES_FOLDER_PATH}}/{{this.slidePlaylistObject.slide[languages[0]]}}_200.webp'
        height='70'
        width='120'

      />
      } @placeholder (minimum 100ms){
        <img
          class='mini_slide'
          src='assets/images/placeholder.jpg'
           height='70'
           width='120'>
      }

  </div>
<!--
  <div *ngIf="state === 'post-live'" class='mini_div'>
    <img
      (click)="playbackAtTimestamp()"
      class='mini_slide'
      [ngClass]="{ 'mini-highlight': highlight }"
&lt;!&ndash;      src='{{environment.apiUrl}}/event/{{this.eventFolder}}/{{constant.SLIDES_FOLDER_PATH}}/{{getActiveSlideNEW()}}_200.webp'&ndash;&gt;
      height='70'
      width='120'
      loading='lazy'
      (error)='handleMissingSlide($event)'
    />
  </div>-->
`,
  styleUrl: './../slidelist.component.css'
})
export class SlidelistItemComponent implements OnInit {
  @Input() slidePlaylistObject: SlidePlaylist;
  @Input() eventFolder: string;
  @Input() path: string;
  @Input() languages: string[];
  @Input() selectedLanguage: string;
  @Input() selectedSlide: ISlideObject;


  @Output() vodEvent = new EventEmitter();
  @Output() centerSlide = new EventEmitter();
  @Output() showElement = new EventEmitter();
  @Output() playAtTimestamp = new EventEmitter();


  protected readonly constant = constants;
  protected readonly environment = environment;

  stateInterval: any;
  state: string;
  highlightInterval: any;
  highlight = false;
  noCacheNumber: number;
  slideDirection: string;

  eventConfigStoreService: EventconfigStoreService = inject(EventconfigStoreService);
  slideStoreService: SlideStoreService = inject(SlideStoreService);

  ngOnInit(): void {
    this.getEventState();
    this.setHighlight();
    this.stateInterval = setInterval(() => { // Initialisierung Interval zum Abruf des Status prelive, live, postlive.
      this.getEventState();
    }, 2000);
    this.slideDirection = this.eventConfigStoreService.eState === 'live' ? 'rtl' : 'ltr';
  }

  setActiveSlide() {
    this.eventConfigStoreService.setActiveSlideNEW(this.slidePlaylistObject);
    this.centerSlide.emit(this.slidePlaylistObject._id);
    this.showElement.emit('slides');
  }

  getLiveSlideNEW() {
    return this.eventConfigStoreService.getLiveSlideNEW();
  }

  getEventState() {
    this.state = this.eventConfigStoreService.getEventState();
  }

  clickVodSlide() {
    console.log('clickVodSlide');
    this.eventConfigStoreService.setActiveSlideNEW(this.slidePlaylistObject);
    let seconds = this.slideTCtoSeconds(this.slidePlaylistObject.timestamp);
    this.vodEvent.emit(seconds);
    this.showElement.emit('slides');
  }

  playbackAtTimestamp(timestamp: string) {
    console.log('playbackAtTimestamp');
    this.playAtTimestamp.emit(this.slideTCtoSeconds(timestamp));
    this.eventConfigStoreService.setActiveSlideNEW(this.slidePlaylistObject);

  }

  slideTCtoSeconds(tc: string): number {
    let slideTimeCodeArray = tc.split(':');
    return (Number.parseInt(slideTimeCodeArray[0]) * 60 +
        Number.parseInt(slideTimeCodeArray[1])) * 60 +
      Number.parseInt(slideTimeCodeArray[2]);
  }



  //zum wiederfinden = SLIDE mySyncSlide setActive
  //Diese methode bestimmt die geaschwindigkeit des schaltens der slides
/*  setHighlight() {
    setInterval(() => {
      this.highlight = this.eventConfigStoreService.getActiveSlideNEW()._id === this.slidePlaylistObject._id;
    }, 100000);
  }*/

  setHighlight() {
    this.highlight = false;
  }




}
