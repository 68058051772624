<div
  class='hr_slide_history'
  [ngClass]="{
    negMarginTop: this.eStatus.phase !== 'post-live' && screenwidth > 1023
  }"
>
  <div class='hr_dropdown_slides'>
    <div class='hr_collapsible_slides' *ngIf='screenwidth > 1023'>
      <button
        type='button'
        class='hr_btn_collapsible'
        (click)='toggleSlideflow()'
      >
        <span
          [ngClass]="{
            active: this.showSlideFlow == 'in'
          }"
          class='arrow'
        >
          <span></span>
          <span></span>
        </span>
        <span>{{ eLangGlob.translateSlideHistory }}</span>
      </button>
    </div>
    <div class='hr_current_slide' *ngIf="eStatus.phase != 'post-live' && screenwidth > 1023">
      <span
        class='cur_slide_left'
        [ngClass]='{
          cur_slide_left_disabled: this.selectedSlide === this.slidePlaylist[0]
        }'
      ></span>
      <button
        class='btn_current_slide'
        [ngClass]='{
          btn_current_slide_disabled: this.selectedSlide === this.slidePlaylist[this.slidePlaylist.length - 1]
        }'
        (click)='setSelectSlide(this.slidePlaylist[this.slidePlaylist.length - 1])'
      >
        <span>{{ eLangGlob.translateCurSlide }}</span>
      </button>
      <span
        class='cur_slide_right'
        [ngClass]='{
          cur_slide_right_disabled: this.selectedSlide === this.slidePlaylist[this.slidePlaylist.length - 1]
        }'
      ></span>
    </div>
  </div>
  <div class='hr_collapsed_slides' [@slideInOut]='showSlideFlow'>
    <div class='historyBox'>
      <button (click)="scroll('left')" class='ff arrow-left'></button>
      <div class='coverflow_style' #slideHistoryContent>
        @for (slideObject of slidePlaylist; track slideObject._id) {
          <lse-slidelist-item
            (click)='setSelectSlide(slideObject)'
            (centerSlide)='scrollToCenter($event)'
            (vodEvent)='handleEvent($event)'
            (showElement)="onshowElement('slides')"
            (playAtTimestamp)='playAtFunction($event)'
            [slideObject]='slideObject'
            [path]='path'
            [selectedLanguage]='selectedLanguage'
            [eventFolder]='eventFolder'
            [languages]='languages'
            #slideDiv
          ></lse-slidelist-item>
        }
      </div>
      <button (click)="scroll('right')" class='ff arrow-right'></button>
    </div>
  </div>
  <hr *ngIf='screenwidth > 1023' />
</div>
