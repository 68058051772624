export const environment = {
  production: false,
  apiUrl: 'https://' + window.location.hostname + '/',
  imgAssets: 'app/',
  email: 'test@hektor-rydzewski.de',
  DEBUG: false,
  cssPrefix: 'app/',
  eventPrefix: 'event/',
  customer: 'test',
  customerShort: 'test', // Suffix vom Ordnerpfad
  fontFamily: 'Arial',
  backgroundColorOptions: ['#0078dc', '#eaf3f8', '#de3a6b'],
  // fontSize: 16
  serverList : ['test.video-archiv.info/test','wow-901.video-archiv.info'],
  serverListLumpensammler : []
};
